




















































































































































































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { IDoctorChamberSchedule } from "../models/DoctorChamberSchedule";
import DoctorChamberService from "../service/DoctorChamberService";
import { IDoctorChamber } from "../models/DoctorChamber";
import DoctorScheduleService from "@/doctor-app/service/DoctorScheduleService";
import DoctorAppointmentService from "@/doctor-app/service/DoctorAppointmentService";
import { IDoctorSchedule } from "@/doctor-app/models/DoctorSchedule";
import { IDoctorAppointment } from "@/doctor-app/models/DoctorAppointment";

@Component({
  components: {},
})
export default class BookAppointmentOld extends Vue {
  public isShow: boolean = true;
  public currentTab = "#step1";
  public enableStep2: boolean = false;
  public enableStep3: boolean = false;
  public showSelectDateTab: boolean = false;
  public showCompleteTab: boolean = false;
  public currentDate: Date = new Date();
  public scheduleList: IDoctorChamberSchedule[] = [];
  public chamberScheduleList: IDoctorChamberSchedule[] = [];
  public schedules: IDoctorSchedule[] = [];
  public appointmentList: IDoctorAppointment[] = [];
  public doctorAppointment: IDoctorAppointment = {
    appointmentDate: this.currentDate,
  } as IDoctorAppointment;
  public phone: any;
  public submited: boolean = false;
  public startTime: any = "";
  public currentIndex: number = -1;

  public allAvaliableSchedules: IDoctorSchedule[] = [];

  public chamberSchedule: IDoctorChamberSchedule = {} as IDoctorChamberSchedule;
  public doctorAppoitment: IDoctorAppointment = {} as IDoctorAppointment;

  public showVerificationTab: boolean = false;
  public showConfirmationTab: boolean = false;
  public verificationCode: string = "";

  public isConfirmed: boolean = false;
  public enableDatepicker: boolean = false;
  public isLoggedIn: boolean = false;
  public isShowGuestLogin: boolean = true;
  public sendMessageCount = 1;
  public dob: Date = new Date();
  public isAppointment: boolean = false;
  // public chamberId: number;
  // public scheduleId: number;

  @Prop({ required: true }) chamberId: any;
  @Prop({ required: true }) currentDay: Date;
  @Prop({ required: true }) scheduleId: any;

  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }

  created() {
    const dict = {
      custom: {
        ChamberName: {
          required: "The chamber name is required.",
        },
        patientName: {
          required: "Patient name is required.",
        },
        Phone: {
          required: "Phone number is required.",
          numeric: "phone number can only contain numeric value.",
          min: "Phone number should be minimum 11 charecter.",
          max: "Phone number cab be maximum 11 charecter.",
        },
        Phone2: {
          required: "Phone number is required.",
          numeric: "phone number can only contain numeric value.",
          min: "Phone number should be minimum 11 charecter.",
          max: "Phone number cab be maximum 11 charecter.",
        },
        dob: {
          required: "DOB number is required.",
          beforeCurrentDate: "Start date must be before current date.",
        },
      },
    };
    this.$validator.localize("en", dict);
    var dd = new Date(this.currentDay);
    this.currentDate = new Date(this.currentDate.setDate(dd.getDate()));

    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
      }
    });
  }

  mounted() {
    this.currentIndex = this.scheduleId;
    var dd = new Date(this.currentDay);
    // this.chamberId = this.chamber;
    this.doctorAppoitment.chamberId = this.chamberId;
    this.scheduleId = this.scheduleId;
    this.currentDate = new Date(this.currentDay);
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
      }
    });
  }

  confirmFrom() {
    this.$validator.validateAll().then((valid) => {
      this.doctorAppointment.chamberId = this.chamberId;
      this.doctorAppointment.chamberSchedulesId = this.scheduleId;
      this.doctorAppointment.appointmentDate = this.currentDate;
      if (valid) {
        this.submited = true;
        DoctorAppointmentService.insertDr(this.doctorAppointment)
          .then((res) => {
            if (res.data.status) {
              // alert("success");
              this.showCompleteTab = true;
              this.showSelectDateTab = false;
              // AlertService.sendMessage("success");
            } else {
              this.submited = false;
              alert("The time slot "+this.doctorAppointment.startTime+" is not available please select a different time slot and confirm ");
              //AlertService.sendMessage("success");
            }
          })
          .catch((err) => {
            this.submited = true;
          });
      } else {
        this.submited = false;
      }
    });
  }
  getScheduleId(value: any, value2: any, index: number) {
    this.scheduleId = value;
    // this.currentIndex = value;
    this.startTime = value2;
    this.currentIndex = value;
  }

  searchFrom() {
    this.appointmentList = [];
    this.dob = this.doctorAppointment.dob;
    //     console.log(this.dob.toISOString());

    //  console.log( this.dob.toLocaleString('en', { timeZone: 'Asia/Dhaka' }));
    if (this.dob == null) this.dob = new Date("2120-04-13T00:00:00.000+08:00");
    //     //   alert(this.dob);
    //            var d = new Date("2020-04-13T00:00:00.000+08:00"); /* midnight in China on April 13th */
    //      this.dob.toLocaleString();
    //     //   alert(d);
this.doctorAppointment.patientName = typeof(this.doctorAppointment.patientName)=='undefined'?'':this.doctorAppointment.patientName;
this.doctorAppointment.phone = typeof(this.doctorAppointment.phone)=='undefined'?'':this.doctorAppointment.phone;
this.doctorAppointment.patientId = typeof(this.doctorAppointment.patientId)=='undefined'?'':this.doctorAppointment.patientId;

    DoctorAppointmentService.getAppointmentSearch(
      this.doctorAppointment.phone,
      this.doctorAppointment.patientId,
      this.doctorAppointment.patientName,
      this.dob
    ).then((res) => {
      if (res.data.result) {
        this.appointmentList = res.data.result;

        this.isAppointment = false;
      }

      if (res.data.messageType == "NoData") {
        this.isAppointment = true;
      }
    });
  }

  getOldPatient(value: any) {
    DoctorAppointmentService.getAppointmentById(value).then((res) => {
      if (res.data.result) {
        this.doctorAppointment = res.data.result;
      }
    });
  }
  findSchedule(value: number) {
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + value));
    let day = this.currentDate.getDay();
    this.scheduleList = this.chamberScheduleList.filter((s) => s.day == day) || [];
    this.onChangeSchedule();
  }

  onChangeSchedule() {
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      // if (res.data.result) {
      //   this.schedules = res.data.result;
      // }
      this.allAvaliableSchedules = [];
      if (res.data.result) {
        this.schedules = res.data.result;
        this.allAvaliableSchedules = this.schedules.filter((s) => s.isBooked == false) || [];
      }
    });
  }
  onchangeCalendar(e: any) {
    this.$nextTick(() => this.onChangeSchedule());
  }
}
