

























































































































































































































import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import { IDoctorChamberSchedule } from "../models/DoctorChamberSchedule";
import DoctorChamberService from "../service/DoctorChamberService";
import { IDoctorChamber } from "../models/DoctorChamber";
import DoctorScheduleService from "@/doctor-app/service/DoctorScheduleService";
import DoctorAppointmentService from "@/doctor-app/service/DoctorAppointmentService";
import { IDoctorSchedule } from "@/doctor-app/models/DoctorSchedule";
import { IDoctorAppointment } from "@/doctor-app/models/DoctorAppointment";
@Component({
  components: {},
})
export default class BookAppointmentNew extends Vue {
  public isShow: boolean = true;
  public currentTab = "#step1";
  public enableStep2: boolean = false;
  public showCompleteTab: boolean = false;
  public currentDate: Date = new Date();
  public scheduleList: IDoctorChamberSchedule[] = [];
  public chamberScheduleList: IDoctorChamberSchedule[] = [];
  public chambers: IDoctorChamber[] = [];
  public schedules: IDoctorSchedule[] = [];
  public doctorAppointment: IDoctorAppointment = { appointmentDate: this.currentDate } as IDoctorAppointment;
  public submited: boolean = false;
  public startTime: any = "";
  public currentIndex: number = -1;
  @Prop({ required: true }) chamberId: any;
  @Prop({ required: true }) currentDay: Date;
  @Prop({ required: true }) scheduleId: any;
  @Emit("close")
  close() {
    this.isShow = false;
    return this.isShow;
  }
  dataGet(f: IDoctorAppointment) {
    this.doctorAppointment = f;
  }
  created() {
    const dict = {
      custom: {
        ChamberName: {
          required: "The chamber name is required.",
        },
        patientName: {
          required: "Patient name is required.",
        },
        Phone: {
          required: "Phone number is required.",
          numeric: "phone number can only contain numeric value.",
          min: "Phone number should be minimum 11 charecter.",
          max: "Phone number cab be maximum 11 charecter.",
        },
        DOB: {
          required: "Date of birth is required",
        },
      },
    };
    this.$validator.localize("en", dict);
    DoctorChamberService.GetListByUser().then((res) => {
      if (res.data.result) {
        this.chambers = res.data.result;
      }
    });
  }
  mounted() {
    this.currentIndex = this.scheduleId;
    this.chamberId = this.chamberId;
    var dd = new Date(this.currentDay);
    this.currentDate = new Date(this.currentDate.setDate(dd.getDate()));
    this.currentDate = new Date(this.currentDay);

    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      if (res.data.result) {
        this.schedules = res.data.result;
        //console.log(this.schedules);
      }
    });
  }
  appointmentFrom() {
    this.$validator.validate().then((valid) => {
      this.doctorAppointment.chamberId = this.chamberId;
      this.doctorAppointment.chamberSchedulesId = this.scheduleId;
      this.doctorAppointment.appointmentDate = this.currentDate;
      if (valid) {
        this.submited = true;
        DoctorAppointmentService.insertDr(this.doctorAppointment)
          .then((res) => {
            if (res.data.status) {
              this.showCompleteTab = true;
              DoctorAppointmentService.refreshAptSchedule(true);
              // AlertService.sendMessage("success");
            } else {
              this.submited = false;
              alert("Time Slot is not exists");
              //AlertService.sendMessage("success");
            }
          })
          .catch((err) => {
            // console.log(err);
            this.submited = true;
          });
      } else {
        this.submited = false;
      }
    });
  }
  onChangeSchedule() {
    DoctorScheduleService.GetScheduleListByDate(this.chamberId, this.currentDate).then((res) => {
      this.schedules = [];
      if (res.data.result) {
        this.schedules = res.data.result;
        //console.log(this.schedules);
      }
    });
  }
  onChange(event: any) {
    this.chamberId = event.target.value;
    this.onChangeSchedule();
  }
  getScheduleId(value: any, value2: any, index: number) {
    this.scheduleId = value;
    this.startTime = value2;
    this.currentIndex = value;
    //alert(this.currentIndex);
  }
  findSchedule(value: number) {
    this.currentDate = new Date(this.currentDate.setDate(this.currentDate.getDate() + value));
    let day = this.currentDate.getDay();
    this.scheduleList = this.chamberScheduleList.filter((s) => s.day == day) || [];
    this.onChangeSchedule();
  }
  onchangeCalendar(e: any) {
    this.$nextTick(() => this.onChangeSchedule());
  }
}
